import React from "react";
import { useState } from "react";
import { navigate } from "gatsby";
import { Link } from "gatsby";
function HeroSection({ data, locale }) {
  const [slug, setSlug] = useState("");
  const handleClick = (val) => {
    setSlug(val);
    navigate(`/cloud-platforms/#${val}`, { replace: true });
  };

  React.useEffect(() => {
    let selectedSlug = window?.location.href.split("#")[1];
    if (selectedSlug) {
      setSlug(selectedSlug);
    } else {
      navigate(`/cloud-platforms/#google-cloud`, { replace: true });
    }
  });

  return (
    <div className="platform_upper_class">
      <div className="platformHeroSection">
        <div className="containerwrap">
          <div className="platformHeroSectionTitle">
            <h1>{data && data.HeroSection.title}</h1>
          </div>
          <div className="platformHeroSectionHeading">
            {data.HeroSection.heading1} <span>{data.HeroSection.heading2}</span>
          </div>
          <div className="platformHeroSectionDescription">
            {data.HeroSection.des}
          </div>
          <div className="opsBtnwrap middelBtn">
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p>{data.HeroSection.button}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="pageNav pltfrmPage">
        <ul className="pageNav-ul">
          <li
            key={1}
            onClick={(e) => handleClick("google-cloud")}
            className={slug === "google-cloud" ? "active" : ""}
          >
            {data.MenuBar.list2}
          </li>
          <li
            key={2}
            onClick={(e) => handleClick("microsoft-azure")}
            className={slug === "microsoft-azure" ? "active" : ""}
          >
            {data.MenuBar.list3}
          </li>
          <li
            key={3}
            onClick={(e) => handleClick("vmware")}
            className={slug === "vmware"  ? "active" : ""}
          >
            {data.MenuBar.list4}
          </li>
          {/* <li><a href="#successstories">{data.MenuBar.list4}</a></li> */}
          {/* <li><a href="#blogsolutions">{data.MenuBar.list5}</a></li> */}
          {/* <li><a href="#testimonials">{data.MenuBar.list6}</a></li>
                    <li><a href="#gettingstarted">{data.MenuBar.list7}</a></li> */}
        </ul>
      </div>
    </div>
  );
}

export default HeroSection;
