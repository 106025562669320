import React, { useMemo } from "react";
import "../styles/platform.scss";
import Layout from "../components/layout";
import { graphql } from "gatsby";
// import Blogs from '../components/Blogs'
// import EnquireNow from '../components/EnquireNow';
// import Service from "../components/platform/service";
import HeroSection from "../components/platform/heroSection";
import Overview from "../components/platform/overview";
// import OpsioAws from "../components/platform/opsioAws"
import WorkingWithUs from "../components/platform/workingWithUs";
import SEO from "../components/seo";

const Platform = ({ data }) => {
  const getPlatformData = (data) => {
    const values = data.allStrapiPlatform.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getPlatformData(data), [data]);

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <div className="">
        <HeroSection data={getData} locale={getData.locale} />
        <Overview locale={getData.locale} />
        {/* <OpsioAws data={getData} /> */}
        <WorkingWithUs data={getData} />
        {/* <EnquireNow data={getData.enquirenow} /> */}
        {/* <Blogs locale={getData.locale} /> */}
      </div>
    </Layout>
  );
};

export default Platform;

export const query = graphql`
  query Platform($locale: String!) {
    allStrapiPlatform(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          metaTitle
          metaDes
          HeroSection {
            button
            des
            heading1
            heading2
            title
          }
          MenuBar {
            list2
            list3
            list4
            list5
          }

          WorkingSection {
            heading1
            heading2
            title
          }
          slug
          locale
          enquirenow {
            btn
            email
            title
          }
        }
      }
    }
  }
`;
